@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'Lato', sans-serif;
}

h1,
h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

h3,
h4 {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}

.scrollToTop {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  font-size: 24px;
  border: none;
  outline: none;
  background-color: #fb923c;
  color: #fff;
  cursor: pointer;
  padding: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.scrollToTop:hover {
  background-color: #ea580c;
}

#global-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255);
  z-index: 9999;
}

.full-screen-image {
  height: 95vh;
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-screen-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.caption-container {
  position: absolute;
  bottom: 5rem;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 10px;
  transform: translateX(-50%);
  left: 50%;
}

.brand-color {
  color: #007bff;
  font-family: 'Montserrat', sans-serif;
}

.content-section {
  font-family: 'Lato', sans-serif;
  font-size: 1.2rem;
  line-height: 1.8;
  color: #333;
}

.content-list {
  list-style-type: disc;
  padding-left: 2rem;
}

.content-list li {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.content-list li strong {
  font-weight: bold;
  color: #007bff;
}

.content-list li em {
  font-style: italic;
}

.content-list li .highlight {
  background-color: #ff0;
}

.content-list li u {
  text-decoration: underline;
}

.section {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 2rem;
  transition: transform 0.2s;
}

.section:hover {
  transform: translateY(-5px);
}

.section h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 1rem;
}

.section ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.section ul li {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.section p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

html,
body {
  height: 100%;
  margin: 0;
}